import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { Circular } from "../Progress";
import "./styles.css";
import { onClearAll, onReadNotification } from "../../action/Notification";
import { Modal } from "../Modal";
import { TicketInfo } from "../Tickets";
import { AlertInfo } from "../Alerts";

class Notification extends React.Component {
  state = {
    route: "",
  };

  onClearAll() {
    this.props.dispatch(onClearAll());
  }

  handleOpenModal(modal, selected_item) {
    let selected_alert = {},
      selected_schedule = {};
    if (modal === "showAlertModal") {
      selected_alert = selected_item;
    }
    if (modal === "showScheduleModal") {
      selected_schedule = selected_item;
    }

    this.setState({
      [modal]: true,
      selected_alert,
      selected_schedule,
      modalTitle: selected_item.title,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onRemoveNotification(notification) {
    this.props.dispatch(onReadNotification(notification));
  }

  render() {
    const { notifications } = this.props;

    let group_notifications = {};

    for (let i = 0; i < notifications.length; i++) {
      const date = moment(notifications[i].date).format("ll");
      if (!group_notifications[date]) {
        group_notifications[date] = [];
      }

      group_notifications[date].push(notifications[i]);
    }

    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }
    return (
      <div className="card">
        <div className="card-header notification-header">
          {this.props.isNav && <h3>Notifications</h3>}
          <span></span>
          {notifications.length > 0 && (
            <div className="clear">
              <Link to="#" className="text-danger">
                Clear all
              </Link>
            </div>
          )}
        </div>
        <div
          className={
            this.props.isNav &&
            notifications.length !== 0 &&
            "notification-container"
          }
        >
          {notifications.length === 0 ? (
            <div className="notification-empty">
              <center>
                <i className="bx bxs-inbox"></i>
                <h3>Empty inbox</h3>
              </center>
            </div>
          ) : (
            Object.keys(group_notifications).map((group, g) => {
              return (
                <>
                  <div key={g} className="notification-title">
                    <span>{group}</span>
                  </div>
                  <div>
                    {group_notifications[group].map((item, i) => {
                      return (
                        <div className="notification-item" key={i}>
                          {item.level ? (
                            <div className="notification-level">
                              <Circular
                                percentage={item.level}
                                strokeWidth={10}
                                showPercentageText
                                width={40}
                                height={40}
                              />
                            </div>
                          ) : (
                            <div
                              className="notification-item-icon-container"
                              onClick={() =>
                                this.handleOpenModal(
                                  item.isAlert
                                    ? "showAlertModal"
                                    : "showScheduleModal",
                                  item
                                )
                              }
                            >
                              <i
                                className={
                                  item.isSchedule
                                    ? "bx bx-timer"
                                    : "bx bx-bell-plus"
                                }
                              ></i>
                            </div>
                          )}

                          <div className="notification-item-info">
                            <b>
                              <h3>{item.title}</h3>
                            </b>
                            <p>{item.body}</p>

                            <div className="notification-date">
                              <span className="notification-date">
                                {moment(item.date).format("lll")}
                              </span>
                            </div>
                          </div>
                          <div
                            className="remove_notification"
                            onClick={this.onRemoveNotification.bind(this, item)}
                          >
                            <i className="bx bx-x text-danger"></i>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })
          )}
        </div>
        {notifications.length > 0 && this.props.isNav && (
          <div className="card-footer">
            <center>
              <Link to="/dashboard/notifications" className="text-primary">
                View All ({notifications.length})
              </Link>
            </center>
          </div>
        )}
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showAlertModal")}
          show={this.state.showAlertModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <AlertInfo
            {...this.state.selected_alert}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showAlertModal"
            )}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showScheduleModal")}
          show={this.state.showScheduleModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <TicketInfo
            {...this.state.selected_schedule}
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showScheduleModal"
            )}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { notifications } = state.Notification;
  return { notifications };
};

export default connect(mapStateToProps)(Notification);

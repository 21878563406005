import React from "react";
import "./styles.css";

const CardCount = (props) => {
  return (
    <div className="card card-count">
      <div className="card-body">
        <h1>{props.title}</h1>
        {props.total_object ? (
          <div className="totals">
            {props.total_object?.map((item, i) => {
              return (
                <div key={i}>
                  <span>{item.title}:</span>
                  <span className="total_n_ob">{item.total}</span>
                </div>
              );
            })}
          </div>
        ) : (
          <p className="total">{props.total}</p>
        )}
      </div>
    </div>
  );
};

export default CardCount;

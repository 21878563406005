import React from "react";
import { Link } from "react-router-dom";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../utils/storage";
import { ENDPOINT } from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { connect } from "react-redux";
import filtersData from "../../utils/filtersData";

class PerPark extends React.Component {
  state = {
    data: [],
    user: {},
    selected_level: {},
    page:1,
    limit:5
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getRevenues(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getRevenues(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;
    let copyFilters = { ...filters };
    delete copyFilters.month;
    delete copyFilters.year;

    let request_body = {
      page,
      limit,
      tag: "park",
      category: "rent",
      filters: copyFilters,
    };

    delete request_body.start_date;
    delete request_body.end_date;

    return filtersData(request_body);
  }

  getRevenues(isLoading) {
    const { user } = this.state;

    let request_body = this.returnFilters();

    if (this.props.all) {
      delete request_body.page;
      delete request_body.limit;
    }

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let results = [];

        for (let el of res?.data?.data || res.data) {
          results.push({
            ...el,
            park_id: el.park_id[0],
          });
        }
        this.setState({
          isLoading: false,
          data: results,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleOpenModal(modal, selected_item) {
    let selected_bin = {},
      selected_level = {},
      modalTitle = "";

    if (modal === "showModal") {
      selected_level = selected_item;
      modalTitle = "Alerts";
    }

    if (modal === "showBinModal") {
      selected_bin = selected_item.bin_info;
      modalTitle = "#" + selected_item.bin_info.bin_id;
    }

    this.setState({
      [modal]: true,
      modalTitle,
      selected_level,
      selected_bin,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  render() {
    return (
      <div className="card" style={this.props.styles}>
        {!this.props.all && (
          <div className="card-header">
            <h3>Rent Revenues - Top 5 Parks</h3>
          </div>
        )}
        <div className="card-body" style={{ paddingBottom: 0 }}>
          <Table
            data={this.state.data}
            no_bordered
            style={{ marginBottom: 0 }}
            isLoading={this.state.isLoading}
            headers={[
              {
                title: "Bus Park",
                key: "park_id.park_id",
              },
              {
                title: "No Tickets",
                key: "count",
              },
              {
                title: "Total Revenues",
                key: "total_revenues",
                type: "amount",
              },
            ]}
          />
        </div>
        {this.state.data.length > 0 && !this.props.all && (
          <center className="card-footer border-top">
            <Link
              to="/dashboard/rent_revenues_per_park"
              className="text-primary"
            >
              <b>View More</b>
            </Link>
          </center>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(PerPark);

import React from "react";
import { Button } from "../Button";
import { Input, Select } from "../Input";

import "./styles.css";
import { ENDPOINT } from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import { availables } from "../../constants/strings";
import formatSelectData from "../../utils/formatSelectData";

const servicefees = [
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];
class NewVehicle extends React.Component {
  state = {
    password: "",
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    plate_number: "",
    available: availables[0],
    companies: {},
    search_company: "",
    isFetchingCompany: false,
    parks: [],
    servicefee: servicefees[0],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getCompanies(true);
    this.getParks(true);

    if (this.props._id && this.props._id !== "") {
      let _available = {},
        _organization = {},
        _park_id = {},
        _servicefee = {},
        {
          available,
          organization_id,
          reference_park_id,
          _id,
          identifier,
          other,
          chassis_number,
        } = this.props;

      if (available) {
        _available = {
          value: available + "",
          label: available + "",
        };
      }

      if (organization_id) {
        _organization = {
          value: organization_id._id + "",
          label: organization_id.name + "",
        };
      }

      if (reference_park_id) {
        _park_id = {
          value: reference_park_id._id + "",
          label: reference_park_id.park_id + "",
        };
      }

      if (Object.keys(other || {}).includes("servicefee")) {
        _servicefee = {
          value: other.servicefee ? "Yes" : "No",
          label: other.servicefee ? "Yes" : "No",
        };
      }

      this.setState({
        _id,
        plate_number: identifier,
        available: _available,
        company: _organization,
        park: _park_id,
        reason: other?.reason || "",
        servicefee: _servicefee,
        chassis_number,
      });
    }
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getCompanies(isFetchingCompany, search_company) {
    this.setState({
      isFetchingCompany,
    });

    const { user } = this.state;

    let body = {
      page: 1,
      limit: 10000,
    };

    if (search_company && search_company !== "") {
      body.search = search_company;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_organization_info",
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options).then((res) => {
      const companies = formatSelectData(res.data.data, "name", "_id");

      this.setState({
        companies,
        isFetchingCompany: false,
      });

      return companies;
    });
  }

  getParks(isFetchingPark, search_park) {
    this.setState({
      isFetchingPark,
    });

    const { user } = this.state;

    let body = {
      page: 1,
      limit: 10000,
    };

    if (search_park && search_park !== "") {
      body.search = search_park;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_park_info",
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    return axios(options).then((res) => {
      const parks = formatSelectData(res.data.data, "park_id", "_id");

      this.setState({
        parks,
        isFetchingPark: false,
      });

      return parks;
    });
  }

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  validateForm() {
    let { error, plate_number, company, park } = this.state;

    if (plate_number === "") {
      error.plate_number = "Name is required";
    }

    if (!company || (company && !company.value) || company.value === "") {
      error.company = "Select company";
    }

    if (!park || (park && !park.value) || park.value === "") {
      error.park = "Select bus park";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      user,
      _id,
      error,
      plate_number,
      company,
      available,
      park,
      reason,
      servicefee,
      chassis_number,
    } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let _company, _available, _park;

      if (company && company.value) {
        _company = company.value;
      }

      if (available && available.value) {
        _available = available.value;
      }

      if (park && park.value) {
        _park = park.value;
      }

      let other = {};

      if (reason !== "") {
        other["reason"] = reason;
      }

      if (servicefee) {
        other["servicefee"] = servicefee.value === "Yes" ? true : false;
      }

      let url = ENDPOINT + "/add_ticket_user_info";

      let data = {
        identifier: plate_number,
        organization_id: _company,
        available: _available,
        reference_park_id: _park,
        other,
        status: this.props.status,
        chassis_number,
      };

      if (_id !== "") {
        url = ENDPOINT + "/update_ticket_user_info";
        data.id = _id;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            name: "",
          });

          toastMessage(
            "success",
            `Data ${this.state._id !== "" ? "updated" : "created"} successfully`
          );

          this.props.handleCloseModal();
          this.props.getData(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                options={this.state.companies}
                label="Company:"
                className={`form-control-lg ${
                  this.props.error && "form-control-error"
                }`}
                value={this.state.company}
                onChange={(e) => this.onChangeText("company", e)}
                error={this.state.error.company}
                required
                isLoading={this.state.isFetchingCompany}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={this.state.parks}
                label="Bus Park:"
                className="form-control-lg "
                value={this.state.park}
                onChange={(e) => this.onChangeText("park", e)}
                error={this.state.error.park}
                required
                isLoading={this.state.isFetchingPark}
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Plate Number:"
                required
                className="form-control-lg"
                value={this.state.plate_number}
                onChange={(e) => this.onChangeText("plate_number", e)}
                error={this.state.error.plate_number}
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Chassis Number:"
                className="form-control-lg"
                value={this.state.chassis_number}
                onChange={(e) => this.onChangeText("chassis_number", e)}
                error={this.state.error.chassis_number}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={servicefees}
                label="Service Fee:"
                className="form-control-lg "
                value={this.state.servicefee}
                onChange={(e) => this.onChangeText("servicefee", e)}
                error={this.state.error.servicefee}
                required
              />
            </div>
            {this.state._id && this.state._id !== "" && (
              <div className="col-md-12">
                <Input
                  label="Reason:"
                  className="form-control-lg"
                  value={this.state.reason}
                  onChange={(e) => this.onChangeText("reason", e)}
                  error={this.state.error.reason}
                />
              </div>
            )}
            <div className="col-md-12">
              <Select
                options={availables}
                label="Available:"
                className="form-control-lg "
                value={this.state.available}
                onChange={(e) => this.onChangeText("available", e)}
                error={this.state.error.available}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default NewVehicle;

import axios from "axios";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CardCount } from "../../components/CardCount";
import { Header } from "../../components/Header";
import { ENDPOINT } from "../../constants/api";
import filtersData from "../../utils/filtersData";
import { numberWithCommas } from "../../utils/formatCurrency";
import { getStorage } from "../../utils/storage";
import toastMessage from "../../utils/toastMessage";

class Home extends React.Component {
  state = {
    user: {},
    total_Parks: 0,
    route: "",
    users: [],
    total_vehicles: "...",
    total_companies: "...",
    total_parking_revenues: "...",
    total_servicefee_revenues: "...",
    total_rent_revenues: "...",
  };

  componentDidMount = async () => {
    const user = await getStorage();

    await this.setState({ user });

    this.getParkingInsight(true);
    this.getServiceFeeInsight(true);
    this.getRentInsight(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getParkingInsight(true);
      this.getRentInsight(true);
      this.getServiceFeeInsight(true);
    }
  }

  getParkingInsight(isLoading) {
    this.getParks(isLoading);
    // this.getCheckOutTickets(isLoading);
    // this.getCheckInTickets(isLoading);
    // this.getCompanies(isLoading);
    // this.getVehicles(isLoading);
    this.getParkingRevenues(isLoading);
  }

  getRentInsight(isLoading) {
    this.getRentRevenues(isLoading);
  }

  getServiceFeeInsight(isLoading) {
    this.getServiceFeeRevenues(isLoading);
  }

  getCheckInTickets(isLoading) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    const request_body = filtersData(params);

    request_body.status = "pending";
    request_body.tag = "count";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoading: false,
          total_ticket_in: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getCheckOutTickets(isLoading) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    const request_body = filtersData(params);

    request_body.status = "validated";
    request_body.tag = "count";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoading: false,
          total_ticket_validated: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getCompanies(isLoading) {
    const { user } = this.state;

    let request_body = {};

    request_body.tag = "count";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_organization_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data, count } = res.data;

        this.setState({
          isLoading: false,
          total_companies: data.length,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getVehicles(isLoading) {
    const { user } = this.state;

    const { page, limit } = this.state;
    const { filters } = this.props;

    let params = {
      status: "vehicle-active",
      filters,
      page,
      limit,
      parkFilterType: "reference_park_id",
    };

    const request_body = filtersData(params);

    request_body.tag = "count";
    request_body.status = "vehicle-active";

    this.setState({
      isLoading,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          isLoading: false,
          total_vehicles: data,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getParkingRevenues(isLoadingParking) {
    const { user } = this.state;
    const { filters } = this.props;

    let params = {
      filters,
      parkFilterType: "park_id",
      vehicleFilterType: "ticket_user_ids",
    };

    let request_body = filtersData(params);

    request_body.tag = "trend";
    request_body.order = "daily";

    this.setState({
      isLoadingParking,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let total_parking_revenues = 0;

        for (let el of res.data) {
          total_parking_revenues += parseInt(el?.total_revenues) || 0;
        }

        this.setState({
          isLoadingParking: false,
          total_parking_revenues,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingParking: false });
      });
  }

  getServiceFeeRevenues(isLoadingServiceFee) {
    const { user } = this.state;

    this.setState({
      isLoadingServiceFee,
    });

    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.start_date;
    delete copyFilters.end_date;

    let params = {
      category: "servicefee",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "trend";
    request_body.order = "daily";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let total_servicefee_revenues = 0;

        for (let el of res.data) {
          total_servicefee_revenues += parseInt(el?.total_revenues) || 0;
        }

        this.setState({
          isLoadingServiceFee: false,
          total_servicefee_revenues,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  getRentRevenues(isLoadingRent) {
    const { user } = this.state;
    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.start_date;
    delete copyFilters.end_date;

    let params = {
      category: "rent",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    let request_body = filtersData(params);

    request_body.tag = "trend";
    request_body.order = "daily";

    this.setState({
      isLoadingRent,
    });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let total_rent_revenues = 0;

        for (let el of res.data) {
          total_rent_revenues += parseInt(el?.total_revenues) || 0;
        }


        this.setState({
          isLoadingRent: false,
          total_rent_revenues,
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingRent: false });
      });
  }

  getParks(isLoadingBusPark) {
    const { user } = this.state;
    const { filters } = this.props;

    this.setState({ isLoadingBusPark });

    let request_body = {
      tag: "count",
      status: "active",
    };

    if (filters.locations && filters.locations.value) {
      request_body.id = filters.locations.value;
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_park_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const total_Parks = res.data.data;
        this.setState({ total_Parks, isLoadingBusPark: false });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoadingBusPark: false });
      });
  }

  handlePressCount(route) {
    this.setState({ route });
  }

  render() {
    if (this.state.route !== "") {
      return <Redirect to={this.state.route} />;
    }

    let list_filters = [];

    if (this.props.location) {
      list_filters.push({
        name: "Park Station",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.locations &&
          this.props.filters.locations.value
            ? this.props.filters.locations.label
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.company) {
      list_filters.push({
        name: "company",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.companies &&
          this.props.filters.companies.value
            ? this.props.filters.companies.label
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.vehicle) {
      list_filters.push({
        name: "vehicle",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.vehicles &&
          this.props.filters.vehicles.length > 0
            ? this.props.filters.vehicles
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.paymentmethod) {
      list_filters.push({
        name: "paymentmethod",
        position: "right",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.paymentmethod &&
          this.props.filters.paymentmethod.value
            ? this.props.filters.paymentmethod.label
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.user_account) {
      list_filters.push({
        name: "user_account",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.users &&
          this.props.filters.users.length > 0
            ? this.props.filters.users
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.date) {
      list_filters.push({
        name: "date",
        position: "right",
        isDropdown: true,
        selected: [
          moment(this.props.filters.start_date).format("DD-MM-YYYY"),
          moment(this.props.filters.end_date).format("DD-MM-YYYY"),
        ],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.month) {
      list_filters.push({
        name: "month",
        position: "right",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.month &&
          this.props.filters.month.length > 0
            ? this.props.filters.month
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    if (this.props.year) {
      list_filters.push({
        name: "year",
        position: "right",
        isDropdown: true,
        selected:
          this.props.filters &&
          this.props.filters.year &&
          this.props.filters.year.length > 0
            ? this.props.filters.year
            : ["All"],
        clickBehaviorId: "dropdownMenuClickableInside",
        autoCloseType: "outside",
      });
    }

    return (
      <div>
        <Header filters={list_filters} />
        <hr />
        {this.props.isCountCard && (
          <div className="row">
            <>
              <div className="col-6 col-md-3">
                <CardCount
                  title="Total Parking Revenues"
                  total={
                    this.state.isLoadingParking
                      ? "..."
                      : "RWF " +
                        numberWithCommas(this.state.total_parking_revenues)
                  }
                />
              </div>
              <div
                className="col-6 col-md-3"
                onClick={() => (window.location.href = "/dashboard/services")}
              >
                <CardCount
                  title="Total Rent Revenues"
                  total={
                    this.state.isLoadingRent
                      ? "..."
                      : "RWF " +
                        numberWithCommas(this.state.total_rent_revenues)
                  }
                />
              </div>
              <div className="col-6 col-md-3">
                <CardCount
                  title="Total Service Fee Revenues"
                  total={
                    this.state.isLoadingServiceFee
                      ? "..."
                      : "RWF " +
                        numberWithCommas(this.state.total_servicefee_revenues)
                  }
                />
              </div>
              {/* <div className="col-6 col-md-3">
                  <CardCount
                    title="Total Number Tickets | Validation"
                    total_object={[
                      {
                        total: this.state.total_ticket_in,
                        title: "Tickets",
                      },
                      {
                        total: this.state.total_ticket_validated,
                        title: "Validation",
                      },
                    ]}
                  />
                </div> */}
              <div className="col-6 col-md-3">
                <CardCount
                  title="Number of Active Smart Parks"
                  total={
                    this.state.isLoadingBusPark ? "..." : this.state.total_Parks
                  }
                />
              </div>
            </>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(Home);

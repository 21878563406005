import React from "react";
import "./styles.css";
import { Checkbox, Input } from "../Input";
import { onFilter } from "../../action/Filters";
import { connect } from "react-redux";
import { payment_methods } from "../../constants/strings";

class PaymentMethod extends React.Component {
  state = {
    years: [],
    selected_method: [],
    isLoading: false,
    error: {},
  };

  handleCheck(item) {
    this.props.dispatch(onFilter({ paymentmethod: item }));
  }

  onResetCheck() {
    this.props.dispatch(
      onFilter({
        paymentmethod: {},
      })
    );

    this.setState({
      search_text: "",
    });
  }

  render() {
    return (
      <div className="card cdropdown-container">
        <div className="card-body">
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="cdropdown-content"
            >
              <h1 className="separator-title">Payment Method</h1>
              {this.props.filters && this.props.filters.paymentmethod && (
                <a
                  href="#"
                  className="text-danger"
                  onClick={this.onResetCheck.bind(this)}
                >
                  <b>{`Clear`}</b>
                </a>
              )}
            </div>
            {payment_methods.map((item, i) => {
              let checked = false;

              if (this.props?.filters?.paymentmethod?.value === item.value) {
                checked = true;
              }

              return (
                <div className="cdropdown-item" key={i}>
                  <Checkbox
                    name={item.label}
                    handleCheck={this.handleCheck.bind(this, item)}
                    checked={checked}
                  />
                </div>
              );
            })}
          </>
        </div>
      </div>
      // </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(PaymentMethod);

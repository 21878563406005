import React from "react";
import "./styles.css";
import { Checkbox, Input, Select } from "../Input";
import axios from "axios";
import { ENDPOINT } from "../../constants/api";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import { LoadingSpinner } from "../LoadingSpinner";
import { onFilter } from "../../action/Filters";
import { connect } from "react-redux";
import formatSelectData from "../../utils/formatSelectData";
import filtersData from "../../utils/filtersData";

let copyVehicle = [];

class Vehicles extends React.Component {
  state = {
    vehicle: [],
    selected_vehicle: [],
    isLoading: false,
    error: {},
    limit: 1000,
    page: 1,
    vehicles: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getVehicles(true);
  };

  getVehicles(isLoading) {
    const { user, page, limit } = this.state;
    const { filters } = this.props;

    this.setState({ isLoading });

    let request_body = {
      page,
      limit,
      status: "vehicle-active",
      filters,
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_ticket_user_info",
      data: filtersData(request_body),
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const vehicles = formatSelectData(res.data.data, "identifier", "_id");

        copyVehicle = vehicles.slice(0);

        this.setState({ vehicles, isLoading: false });
      })
      .catch((error) => {
        toastMessage("error", error);

        this.setState({ isLoadingVehicles: false });
      });
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  handleCheck(item) {
    let selected_vehicle = this.state.selected_vehicle;

    let index = selected_vehicle.indexOf(item);

    if (index !== -1) {
      selected_vehicle.splice(index, 1);
    } else {
      selected_vehicle.push(item);
    }

    this.setState(
      {
        selected_vehicle,
      },
      () => {
        this.props.dispatch(onFilter({ vehicles: selected_vehicle }));
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyVehicle.length; i++) {
      if (
        copyVehicle[i].identifier
          .toUpperCase()
          .indexOf(search_text.toUpperCase()) !== -1
      ) {
        array.push(copyVehicle[i]);
      }
    }

    this.setState({
      vehicles: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ vehicles: [] }));

    this.setState({
      selected_vehicle: [],
      search_text: "",
      vehicle: copyVehicle,
    });
  }

  render() {
    return (
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search vehicle..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />

          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="cdropdown-content"
              >
                <h1 className="separator-title">Vehicles</h1>
                {this.props.filters &&
                  this.props.filters.vehicles &&
                  this.props.filters.vehicles.length > 0 && (
                    <a
                      href="#"
                      className="text-danger"
                      onClick={this.onResetCheck.bind(this)}
                    >
                      <b>{`Clear (${this.props.filters.vehicles.length})`}</b>
                    </a>
                  )}
              </div>
              {this.state.vehicles.map((item, i) => {
                return (
                  <div className="cdropdown-item" key={i}>
                    <Checkbox
                      name={item.identifier}
                      handleCheck={this.handleCheck.bind(this, item)}
                      checked={this.state.selected_vehicle.includes(item)}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Vehicles);

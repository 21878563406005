import React from "react";
import "./styles.css";
import { Bar } from "react-chartjs-2";
import { getStorage } from "../../utils/storage";
import { ENDPOINT } from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { connect } from "react-redux";
import { LoadingSpinner } from "../LoadingSpinner";
import filtersData from "../../utils/filtersData";
import months from "../../constants/months";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          min: 0,
        },
      },
    ],
  },
};

class TotalServiceFeeRevenues extends React.Component {
  state = {
    data: [],
    user: {},
    selected_alert: {},
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getRevenues(true);
  };

  componentDidUpdate(prevProps) {
    if (this.state.user.token && prevProps.filters !== this.props.filters) {
      this.getRevenues(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { page, limit } = this.state;
    const { filters } = this.props;

    let request_body = {
      category: "servicefee",
      page,
      limit,
      filters,
      parkFilterType: "reference_park_id",
    };

    return filtersData(request_body);
  }

  getRevenues(isLoading) {
    const { user } = this.state;

    const { filters } = this.props;

    let copyFilters = { ...filters };

    delete copyFilters.start_date;
    delete copyFilters.end_date;

    let params = {
      category: "servicefee",
      filters: copyFilters,
      parkFilterType: "reference_park_id",
    };

    this.setState({
      isLoading,
    });

    let request_body = filtersData(params);

    request_body.tag = "trend";
    request_body.order = "monthly";

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_payment_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let group_by_data = {};

        for (let el of res.data) {
          const key = months[el._id];

          if (!group_by_data[key]) {
            group_by_data[key] = {};
          }

          group_by_data[key] = el;
        }

        const labels = Object.keys(group_by_data);
        let chart_data = [];

        for (let el of Object.keys(group_by_data)) {
          chart_data.push(group_by_data[el].total_revenues);
        }

        this.setState({
          isLoading: false,
          data: {
            labels,
            datasets: [
              {
                label: "Monthly - RWF",
                data: chart_data,
                borderRadius: 15,
                backgroundColor: "#05c605",
                borderColor: "rgba(0,0,0,1)",
                barPercentage: 0.5,
              },
            ],
          },
        });
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="chart-container">
        <div className="card">
          <div className="card-header">
            <h3>Total Vehicle Service Fee Revenues</h3>
          </div>
          <div className="card-body" style={{ height: 280 }}>
            {this.state.isLoading ? (
              <LoadingSpinner />
            ) : (
              <Bar data={this.state.data} options={options} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return { filters };
};

export default connect(mapStateToProps)(TotalServiceFeeRevenues);

import React from "react";
import { Button } from "../Button";
import { Input, Select } from "../Input";

import "./styles.css";
import { ENDPOINT } from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import {
  availables,
  countries,
  payment_methods,
} from "../../constants/strings";

class Company extends React.Component {
  state = {
    password: "",
    error: {},
    isSubmitting: false,
    user: {},
    _id: "",
    country: countries[0],
    category: "",
    name: "",
    address: {},
    available: availables[0],
    phone_number: "",
    email_address: "",
    tin: "",
    payment_method: payment_methods[0],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    if (this.props._id && this.props._id !== "") {
      let { country, category, available, other, payment_method } = this.props;
      let _country = {},
        _available = {},
        _payment_method = {},
        _category = [],
        email = other?.email,
        phone_number = other?.phone_number,
        tin = other?.tin;

      if (country) {
        _country = {
          value: country,
          label: country,
        };
      }
      if (category) {
        if (Array.isArray(category) && category.length > 0) {
          _category = category[0];
        } else {
          _category = category;
        }
      }
      if (available) {
        _available = {
          value: available + "",
          label: available + "",
        };
      }

      if (payment_method) {
        _payment_method = {
          value: payment_method,
          label: payment_method,
        };
      }

      this.setState({
        ...this.props,
        available: _available,
        country: _country,
        category: _category,
        tin,
        email,
        phone_number,
        payment_method: _payment_method,
      });
    }
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  onChangeText = async (name, e) => {
    let error = this.state.error;
    let value = e.target ? e.target.value : e;

    delete error[name];

    await this.setState({
      error,
      [name]: value,
    });
  };

  validateForm() {
    let { error, name } = this.state;

    if (name === "") {
      error.name = "Name is required";
    }

    this.setState({
      error,
    });
  }

  onSubmit = async () => {
    await this.validateForm();

    const {
      user,
      _id,
      error,
      name,
      country,
      category,
      address,
      available,
      email,
      phone_number,
      tin,
      payment_method,
    } = this.state;

    if (Object.keys(error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let _country, _available, _payment_method;

      if (country && country.value) {
        _country = country.value;
      }

      if (available && available.value) {
        _available = available.value;
      }

      if (payment_method && payment_method.value) {
        _payment_method = payment_method.value;
      }

      let url = ENDPOINT + "/add_organization_info";

      let data = {
        name,
        category,
        country: _country,
        available: available.value,
        address,
        account: user.id,
        available: _available,
        other: {
          tin,
          phone_number,
          email,
        },
        payment_method: _payment_method,
      };

      if (_id !== "") {
        url = ENDPOINT + "/update_organization_info";
        data.id = _id;
      }

      const options = {
        method: "POST",
        url,
        data,
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            name: "",
          });

          toastMessage(
            "success",
            `Data ${this.state._id !== "" ? "updated" : "created"} successfully`
          );

          this.props.handleCloseModal();
          this.props.getData(true);
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <Select
                defaultOptions={countries}
                label="Country:"
                className="form-control-lg "
                value={this.state.country}
                onChange={(e) => this.onChangeText("country", e)}
                error={this.state.error.country}
                required
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Name:"
                required
                className="form-control-lg"
                value={this.state.name}
                onChange={(e) => this.onChangeText("name", e)}
                error={this.state.error.name}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Activity:"
                required
                className="form-control-lg"
                value={this.state.category}
                onChange={(e) => this.onChangeText("category", e)}
                error={this.state.error.category}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="TIN:"
                className="form-control-lg"
                value={this.state.tin}
                onChange={(e) => this.onChangeText("tin", e)}
                error={this.state.error.tin}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Email Address:"
                className="form-control-lg"
                value={this.state.email}
                onChange={(e) => this.onChangeText("email", e)}
                error={this.state.error.email}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Phone Number:"
                className="form-control-lg"
                value={this.state.phone_number}
                onChange={(e) => this.onChangeText("phone_number", e)}
                error={this.state.error.phone_number}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={payment_methods}
                label="Payment Method:"
                className="form-control-lg "
                value={this.state.payment_method}
                onChange={(e) => this.onChangeText("payment_method", e)}
                error={this.state.error.payment_method}
              />
            </div>
            <div className="col-md-12">
              <Select
                options={availables}
                label="Available:"
                className="form-control-lg "
                value={this.state.available}
                onChange={(e) => this.onChangeText("available", e)}
                error={this.state.error.available}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="card-footer d-flex align-content-center justify-content-end gap-3">
          <Button
            text="Close"
            className="btn-default btn-lg border"
            onPress={this.props.handleCloseModal}
          />
          <Button
            isSubmitting={this.state.isSubmitting}
            text="Submit"
            className="btn-primary btn-lg"
            onPress={this.onSubmit.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default Company;

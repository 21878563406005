import React from "react";
import "./styles.css";
import { Checkbox, Input, Select } from "../Input";
import axios from "axios";
import { ENDPOINT } from "../../constants/api";
import toastMessage from "../../utils/toastMessage";
import { getStorage } from "../../utils/storage";
import { LoadingSpinner } from "../LoadingSpinner";
import { onFilter } from "../../action/Filters";
import { connect } from "react-redux";
import formatSelectData from "../../utils/formatSelectData";

let copyLocation = [];

class Location extends React.Component {
  state = {
    recent_location: [],
    selected_location: {},
    isLoading: false,
    provinces: [],
    districts: [],
    sectors: [],
    cells: [],
    villages: [],
    error: {},
    limit: 1000,
    page: 1,
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    const { user } = this.state;
    const { access_role } = user;

    if (
      access_role &&
      access_role.only_accessible_parks &&
      access_role.only_accessible_parks.length > 0
    ) {
      return this.getPresetedAccessPark(access_role.only_accessible_parks);
    }

    this.getParks(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getPresetedAccessPark(recent_location) {
    this.setState(
      { recent_location, selected_location: recent_location[0] },
      () => {
        this.props.dispatch(onFilter({ locations: recent_location[0] }));
      }
    );
  }

  getParks(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let body = {
      page,
      limit,
    };

    let url = ENDPOINT + "/get_park_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data } = res.data;

        let recent_location = formatSelectData(data, "park_id", "_id");

        this.setState({
          isLoading: false,
          recent_location,
        });

        if (data.length !== 0) {
          copyLocation = recent_location.slice(0);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  handleCheck(location) {
    this.setState(
      {
        selected_location: location,
      },
      () => {
        this.props.dispatch(onFilter({ locations: location }));
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyLocation.length; i++) {
      if (
        copyLocation[i].toUpperCase().indexOf(search_text.toUpperCase()) !== -1
      ) {
        array.push(copyLocation[i]);
      }
    }

    this.setState({
      recent_location: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ locations: [] }));

    this.setState({
      selected_location: {},
      search_text: "",
      recent_location: copyLocation,
    });
  }

  render() {
    return (
      // <form>
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search location..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />
          {/* <Link to="#">Use current location</Link> */}
          {this.state.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="cdropdown-content"
              >
                <h1 className="separator-title">Locations</h1>
                {this.props.filters &&
                  this.props.filters.locations &&
                  this.props.filters.locations.value && (
                    <a
                      href="#"
                      className="text-danger"
                      onClick={this.onResetCheck.bind(this)}
                    >
                      <b>{`Clear `}</b>
                    </a>
                  )}
              </div>
              {this.state.recent_location.map((item, i) => {
                let checked = false;

                if (this.props.filters.locations?.value === item.value) {
                  checked = true;
                }

                return (
                  <div className="cdropdown-item" key={i}>
                    <Checkbox
                      name={item.label}
                      handleCheck={this.handleCheck.bind(this, item)}
                      checked={checked}
                    />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      // </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(Location);

import React from "react";
import Map from "../components/Map/Map";
import { Home } from "./common";
import { getStorage } from "../utils/storage";
import socket from "../utils/socketIO";
import { ENDPOINT } from "../constants/api";
import axios from "axios";
import { connect } from "react-redux";
import filtersData from "../utils/filtersData";

class ParkMapScreen extends React.Component {
  state = {
    user: {},
    data: [],
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);

    socket.on("ticket_level", (park) => {
      const Parks = this.state.data;

      for (let i = 0; i < Parks.length; i++) {
        if (Parks[i]._id == park._id) {
          Parks[i] = park;
        }
      }
      this.setState({
        data: Parks,
      });
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  returnFilters() {
    const { filters } = this.props;

    let params = {
      tag: "last",
      filters,
      parkFilterType: "id",
    };

    let request_body = filtersData(params);

    delete request_body.month;
    delete request_body.year;
    delete request_body.start_date;
    delete request_body.end_date;
    delete request_body.month_paid_fors;
    delete request_body.year_paid_fors;


    return request_body;
  }

  getData(isLoading) {
    const { user } = this.state;

    this.setState({ isLoading });

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_parklevel_info",
      data: this.returnFilters(),
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let response_data = res.data,
          data = [];

        for (let i = 0; i < response_data.length; i++) {
          response_data[i].last.address = response_data[i].park[0].address;
          data.push({
            ...response_data[i].last,
            park:
              response_data[i].park && response_data[i].park.length > 0
                ? response_data[i].park[0]
                : {},
          });
        }

        this.setState({
          isLoading: false,
          data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const legend = {
      styles: {
        right: 0,
        top: 0,
        margin: 25,
      },
      items: [
        {
          color: "#7A7D7A",
          icon: "bx-trash-alt",
          name: "Low Ticket Level",
        },
        {
          color: "rgb(121, 2, 121)",
          icon: "bx-trash",
          name: "Mid Low Level",
        },
        {
          color: "#000000",
          icon: "bx-trash",
          name: "Mid High Level",
        },
        {
          color: "#FD0C0C",
          icon: "bxs-trash-alt",
          name: "Almost Full Level",
        },
        {
          color: "transparent",
          icon: "bxs-trash",
          name: "N Grouped Parks",
          className: "n_group",
        },
      ],
    };

    return (
      <div>
        <Home
          isCountCard
          location
          date
          company
          mapinsight
          month
          year
          paymentmethod
        />
        <div style={{ position: "relative", marginTop: "1rem" }}>
          <Map
            isLoading={this.state.isLoading}
            legend={legend}
            data={this.state.data}
            type="park_level"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;

  return {
    filters,
  };
};

export default connect(mapStateToProps)(ParkMapScreen);

import React from "react";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../utils/storage";
import { ENDPOINT } from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";

class CompanyInfo extends React.Component {
    state = {
        data: [],
    };

    componentDidMount = async () => {
        await this.getUserLoggedInInfo();

        await this.getData(true);
    };

    getUserLoggedInInfo = async () => {
        const user = await getStorage();
        this.setState({
            user,
        });
    };

    getData(isLoading) {
        const { user } = this.state;

        this.setState({
            isLoading,
        });

        const options = {
            method: "POST",
            url: ENDPOINT + "/get_organization_info",
            data: {
                id: this.props.id ? this.props.id : this.props._id,
            },
            headers: {
                authorization: "Bearer " + user.token,
            },
        };

        axios(options)
            .then((res) => {
                const data = res.data;

                this.setState({
                    data,
                    isLoading: false,
                });
            })
            .catch((error) => {
                toastMessage("error", error);
                this.setState({ isLoading: false });
            });
    }

    render() {
        return (
          <div className="card">
            <div className="card-body">
              <Table
                data={this.state.data}
                no_bordered
                isLoading={this.state.isLoading}
                headers={[
                  {
                    title: "Name",
                    key: "name",
                  },
                  {
                    title: "Country",
                    key: "country",
                  },
                  {
                    title: "Category",
                    key: "category",
                  },
                  {
                    title: "TIN",
                    key: "other.tin",
                  },
                  {
                    title: "Email",
                    key: "other.email",
                  },
                  {
                    title: "Phone Number",
                    key: "other.phone_number",
                  },
                  {
                    title: "Payment Method",
                    key: "payment_method",
                  },
                ]}
              />
            </div>
          </div>
        );
    }
}

export default CompanyInfo;

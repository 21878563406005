import React from "react";
import { Parks } from "../components/Parks";
import { CheckInAdmins } from "../components/CheckAdmin";
import Devices from "../components/Devices/Devices";
import { Tabs } from "../components/Tabs";
import { getStorage } from "../utils/storage";

class DeviceParkscreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  renderBusStation() {
    return (
      <div className="card-body">
        <Parks />
      </div>
    );
  }

  renderDevice() {
    return (
      <div className="card-body">
        <Devices />
      </div>
    );
  }

  renderAdmins() {
    return (
      <div className="card-body">
        <CheckInAdmins />
      </div>
    );
  }

  render() {
    return (
      <div className="card">
        <Tabs
          options={[
            {
              title: "Bus Stations",
              data: this.renderBusStation(),
            },
            {
              title: "Devices",
              data: this.renderDevice(),
            },
            {
              title: "Check-in Admins",
              data: this.renderAdmins(),
            },
          ]}
        />
      </div>
    );
  }
}

export default DeviceParkscreen;

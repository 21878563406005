import axios from "axios";
import React from "react";
import { ENDPOINT } from "../../constants/api";
import toastMessage from "../../utils/toastMessage";
import Table from "../Table/Table";
import { DeleteModal, Modal } from "../Modal";
import "./styles.css";
import NewCheckAdmin from "./NewCheckAdmin";
import { getStorage } from "../../utils/storage";

let copyCheckInAdmins = [];

class CheckInAdmin extends React.Component {
  state = {
    data: [],
    isLoading: true,
    user: {},
    page: 1,
    limit: 10,
    select_admin: {},
    error: {},
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    this.getCheckInAdmins(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };

  getCheckInAdmins(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let body = {
      page,
      limit,
    };

    let url = ENDPOINT + "/get_reviewer_info";

    const options = {
      method: "POST",
      url,
      data: {
        ...body,
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        let { data, count } = res.data;

        if (!data) {
          data = res.data;
        }
        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length !== 0) {
          copyCheckInAdmins = data.slice(0);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });

        toastMessage("error", error);
      });
  }

  handleShowModal(modal, modalTitle, select_admin = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      select_admin,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getCheckInAdmins(true);
      }
    );
  }

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      [name]: e.target.value,
      error,
    });
  }

  validateDelete() {
    let { error, delete_password } = this.state;

    if (delete_password === "") {
      error.delete_password = "Your password is mandatory to confirm delete";
    }

    this.setState({
      error,
    });
  }

  onDelete = async () => {
    let { data, select_admin, user, error, delete_password } = this.state;

    await this.validateDelete();

    if (Object.keys(error).length === 0) {
      this.setState({
        data,
        isDeleting: true,
      });

      let url = ENDPOINT + "/delete_reviewer_info";

      const options = {
        method: "POST",
        url,
        data: { id: select_admin._id, password: delete_password },
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((res) => {
          const index = data.indexOf(select_admin);

          data.splice(index, 1);

          this.setState({
            isDeleting: false,
          });

          toastMessage("success", "Data deleted successful");
          this.handleCloseModal("showDeleteModal");
        })
        .catch((error) => {
          this.setState({
            isDeleting: false,
          });

          toastMessage("error", error);
        });
    }
  };

  handleSearch(e) {
    const search_text = e.target.value;
    let array = [];

    this.setState({
      search_text,
    });

    for (let i = 0; i < copyCheckInAdmins.length; i++) {
      if (
        JSON.stringify(copyCheckInAdmins[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyCheckInAdmins[i]);
      }
    }

    this.setState({
      data: array,
    });
  }

  render() {
    let headers = [
      {
        title: "Name",
        key: "name",
      },
      {
        title: "Contact",
        key: "contact",
      },
      {
        title: "Action",
        key: "action",
      },
    ];

    return (
      <div>
        <Table
          data={this.state.data}
          isSearch
          showAdd
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          addButtonText="Add Reviewer"
          rowPress={this.handleShowModal.bind(this, "showModal", "Reviewer")}
          headers={headers}
          handleAddPressed={this.handleShowModal.bind(
            this,
            "showModal",
            "Reviewer"
          )}
          actions={[
            {
              name: "Edit",
              onPress: (item) =>
                this.handleShowModal("showModal", "Edit Reviewer", item),
            },
            {
              name: "Delete",
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                "Delete Reviewer"
              ),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
        >
          <NewCheckAdmin
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getCheckInAdmins={this.getCheckInAdmins.bind(this)}
            {...this.state.select_admin}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            delete_password={this.state.delete_password}
            onDelete={this.onDelete.bind(this)}
            onChangePassword={this.onChangeText.bind(this)}
            isDeleting={this.state.isDeleting}
          />
        </Modal>
      </div>
    );
  }
}

export default CheckInAdmin;

import moment from "moment";

export default ({
  page,
  limit,
  filters,
  category,
  status,
  parkFilterType,
  vehicleFilterType = "ticket_user_ids",
  startDateType = "start_createdAt",
  endDateType = "end_createdAt",
  tag,
}) => {
  let request_body = {};

  if (page) {
    request_body.page = page;
  }

  if (limit) {
    request_body.limit = limit;
  }

  if (category) {
    request_body.category = category;
  }

  if (status) {
    request_body.status = status;
  }

  if (tag) {
    request_body.tag = tag;
  }

  if (filters?.companies?.value) {
    request_body.organization_id = filters.companies.value;
  } else {
    delete request_body.organization_id;
  }

  if (filters?.locations?.value) {
    request_body[parkFilterType] = filters.locations?.value;
  } else {
    delete request_body[parkFilterType];
  }

  if (filters?.vehicles?.length > 0) {
    request_body[vehicleFilterType] = [];
    for (let el of filters.vehicles) {
      request_body[vehicleFilterType].push(el.value);
    }
  } else {
    delete request_body[vehicleFilterType];
  }

  if (filters?.start_date && filters.end_date) {
    if (filters?.start_date === filters.end_date) {
      var midnight = moment().startOf("day").toString();
      var now = moment().toString();

      request_body[startDateType] = moment(midnight).unix() * 1000;
      request_body[endDateType] = moment(now).endOf("day").unix() * 1000;
    } else {
      request_body[startDateType] = moment(filters.start_date).unix() * 1000;
      request_body[endDateType] =
        moment(filters.end_date).endOf("day").unix() * 1000;
    }
  }

  if (filters?.month?.length > 0) {
    request_body.month_paid_fors = [];

    for (let el of filters.month) {
      request_body.month_paid_fors.push(Number(el.value));
    }
  }

  if (filters?.year?.length > 0) {
    request_body.year_paid_fors = [];

    for (let el of filters.year) {
      request_body.year_paid_fors.push(Number(el.value));
    }
  }

  if (filters?.paymentmethod?.value) {
    request_body.payment_method = filters?.paymentmethod?.value;
  }

  return request_body;
};

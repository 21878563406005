import React from "react";
import "./styles.css";
import { Checkbox, Input, Select } from "../Input";
import { onFilter } from "../../action/Filters";
import { connect } from "react-redux";

let copyYear = [];

class YearList extends React.Component {
  state = {
    years: [],
    selected_year: [],
    isLoading: false,
    error: {},
  };

  componentDidMount = async () => {
    const now_year = new Date().getFullYear();

    let years = [];

    for (let i = 2020; i <= now_year; i++) {
      years.push({
        label: i,
        value: i,
      });
    }

    this.setState({
      years,
    });
  };

  handleCheck(item) {
    let selected_year = this.state.selected_year;

    let index = selected_year.indexOf(item);

    if (index !== -1) {
      selected_year.splice(index, 1);
    } else {
      selected_year.push(item);
    }

    this.setState(
      {
        selected_year,
      },
      () => {
        this.props.dispatch(onFilter({ year: selected_year }));
      }
    );
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyYear.length; i++) {
      if (copyYear[i].toUpperCase().indexOf(search_text.toUpperCase()) !== -1) {
        array.push(copyYear[i]);
      }
    }

    this.setState({
      years: array,
    });
  }

  onResetCheck() {
    this.props.dispatch(onFilter({ year: [] }));

    this.setState({
      selected_year: [],
      search_text: "",
      years: copyYear,
    });
  }

  render() {
    return (
      <div className="card cdropdown-container">
        <div className="card-body">
          <Input
            placeholder="Search year..."
            className="form-control-sm"
            value={this.state.search_text}
            onChange={this.handleSearch.bind(this)}
          />
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="cdropdown-content"
            >
              <h1 className="separator-title">Years</h1>
              {this.props.filters &&
                this.props.filters.year &&
                this.props.filters.year.length > 0 && (
                  <a
                    href="#"
                    className="text-danger"
                    onClick={this.onResetCheck.bind(this)}
                  >
                    <b>{`Clear (${this.props.filters.year.length})`}</b>
                  </a>
                )}
            </div>
            {this.state.years.map((item, i) => {
              let checked = false;

              if (
                this.props.filters.year &&
                this.props.filters.year.filter(
                  (el) => Number(el.value) === Number(item.value)
                ).length > 0
              ) {
                checked = true;
              }

              return (
                <div className="cdropdown-item" key={i}>
                  <Checkbox
                    name={item.label}
                    handleCheck={this.handleCheck.bind(this, item)}
                    checked={checked}
                  />
                </div>
              );
            })}
          </>
        </div>
      </div>
      // </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { filters } = state.Filters;
  return {
    filters,
  };
};

export default connect(mapStateToProps)(YearList);

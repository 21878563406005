import React from "react";
import Table from "../Table/Table";
import "./styles.css";
import { getStorage } from "../../utils/storage";
import { ENDPOINT } from "../../constants/api";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import { DeleteModal, Modal } from "../Modal";
import { CSVLink } from "react-csv";
import exportPDF from "../../utils/exportPDF";
import { NewPrice } from ".";

let copyData = [];
class Pricing extends React.Component {
  state = {
    data: [],
    page: 1,
    limit: 10,
    csvData: [],
    user: {},
    selected_price: {},
    delete_password: "",
    isDeleting: false,
    error: {}
  };

  componentDidMount = async () => {
    await this.getUserLoggedInInfo();

    await this.getData(true);
  };

  getUserLoggedInInfo = async () => {
    const user = await getStorage();
    this.setState({
      user,
    });
  };


  getData(isLoading) {
    const { user, page, limit } = this.state;

    this.setState({
      isLoading,
    });

    let request_body = {
      limit,
      page,
      name:"Parking"
    }

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const { data, count } = res.data;

        this.setState({
          data,
          isLoading: false,
          totalPageCount: count,
        });

        if (data.length > 0) {
          copyData = data.slice(0);
        }
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleOpenModal(modal, selected_price) {
    this.setState({
      [modal]: true,
      selected_price: selected_price,
      modalTitle: "Price"
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  handleSearch(e) {
    const search_text = e.target.value;

    this.setState({ search_text });

    let array = [];

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toLowerCase()
          .indexOf(search_text.toLowerCase()) !== -1
      ) {
        array.push(copyData[i]);
      }
    }

    this.setState({ data: array });
  }

  downloadExcel() {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let request_body = {
      name: "Parking",
      page: 1,
      limit: 100000,
    };

    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_info",
      data: request_body,
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data.data;

        let csvData = [];


        for (let el of data) {
          delete el.__v;
          delete el._id;
          delete el.updatedAt;

          let other = el.other;

          delete el.other;

          csvData.push({
            ...el,
            ...other,
          });
        }

        this.setState(
          {
            csvData,
            isLoading: false,
          },
          () => {
            this.refs.csvDownload?.link.click();
          }
        );
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  downloadPDF() {
    const headers = this.returnTableHeaders();

    delete headers[headers.length - 1];

    const { user } = this.state;

    this.setState({
      isLoading: true,
    });


    const options = {
      method: "POST",
      url: ENDPOINT + "/get_service_info",
      data: {
        page: 1,
        limit: 100000,
        name: "Parking",
      },
      headers: {
        authorization: "Bearer " + user.token,
      },
    };

    axios(options)
      .then((res) => {
        const data = res.data.data;

        this.setState({ isLoading: false });

        exportPDF("Services", headers, data);
      })
      .catch((error) => {
        toastMessage("error", error);
        this.setState({ isLoading: false });
      });
  }

  handleShowModal(modal, modalTitle, selected_price = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selected_price,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  onChangeText(name, e) {
    let { error } = this.state;

    delete error[name];

    this.setState({
      [name]: e.target.value,
      error,
    });
  }

  validateDelete() {
    let { error, delete_password } = this.state;

    if (delete_password === "") {
      error.delete_password = "Your password is mandatory to confirm delete";
    }

    this.setState({
      error,
    });
  }

  onDelete = async () => {
    let { data, selected_price, user, error, delete_password } = this.state;

    await this.validateDelete();

    if (Object.keys(error).length === 0) {
      this.setState({
        data,
        isDeleting: true,
      });

      let url = ENDPOINT + "/delete_service_info";

      const options = {
        method: "POST",
        url,
        data: { id: selected_price._id, password: delete_password },
        headers: {
          authorization: "Bearer " + user.token,
        },
      };

      axios(options)
        .then((res) => {
          const index = data.indexOf(selected_price);

          data.splice(index, 1);

          this.setState({
            isDeleting: false,
          });

          toastMessage("success", "Data deleted successful");
          this.handleCloseModal("showDeleteModal");

          this.getData(false)
        })
        .catch((error) => {
          this.setState({
            isDeleting: false,
          });

          toastMessage("error", error);
        });
    }
  };


  returnTableHeaders() {
    let headers = [
      {
        title: "Service",
        key: "name",
      },
      {
        title: "Currency",
        key: "other.currency",
      },
      {
        title: "Amount",
        key: "price",
      },
      {
        title: "Action",
        key: "action",
      },
    ];
    return headers;
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <Table
              data={this.state.data}
              no_bordered
              isSearch
              style={{ marginBottom: 0 }}
              totalPageCount={this.state.totalPageCount}
              page={this.state.page}
              limit={this.state.limit}
              search_text={this.state.search_text}
              handleSearch={this.handleSearch.bind(this)}
              isLoading={this.state.isLoading}
              handlePagination={this.handlePagination.bind(this)}
              headers={this.returnTableHeaders()}
              showAdd
              addButtonText="Add Price"
              handleAddPressed={this.handleShowModal.bind(
                this,
                "showModal",
                "Create Price"
              )}
              actions={[
                {
                  name: "Edit",
                  onPress: (item) =>
                    this.handleShowModal("showModal", "Edit Price", item),
                },
                {
                  name: "Delete",
                  onPress: this.handleShowModal.bind(
                    this,
                    "showDeleteModal",
                    "Delete Price"
                  ),
                },
              ]}
              filters={[
                {
                  type: "export",
                  title: "Export",
                  button_type: "dropdown",
                  icon: "bx bxs-download",
                  options: [
                    {
                      name: "PDF",
                      onPress: this.downloadPDF.bind(this),
                    },
                    {
                      name: "CSV",
                      onPress: this.downloadExcel.bind(this),
                    },
                  ],
                },
              ]}
            />
          </div>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showModal")}
            show={this.state.showModal}
            title={this.state.modalTitle}
            showHeaderBottomBorder={false}
          >
            <NewPrice
              handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
              getData={this.getData.bind(this)}
              {...this.state.selected_price}
            />
          </Modal>
          <Modal
            handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
            show={this.state.showDeleteModal}
            title={this.state.modalTitle}
          >
            <DeleteModal
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showDeleteModal"
              )}
              error={this.state.error.delete_password}
              delete_password={this.state.delete_password}
              onDelete={this.onDelete.bind(this)}
              onChangePassword={this.onChangeText.bind(this)}
              isDeleting={this.state.isDeleting}
            />
          </Modal>
          <CSVLink
            ref="csvDownload"
            filename={"Services" + new Date().getTime()}
            data={this.state.csvData}
          ></CSVLink>
        </div>
      </>
    );
  }
}

export default Pricing

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//screens
import LoginScreen from "./app/screen/LoginScreen";
import ResetPasswordScreen from "./app/screen/ResetPasswordScreen";
import HomeDashBoardScreen from "./app/screen/HomeDashBoardScreen";
import ForgotPasswordScreen from "./app/screen/ForgotPasswordScreen";
import ServiceFeeRevenuesPerParkScreen from "./app/screen/ServiceFeeRevenuesPerParkScreen";
import ServiceFeeRevenuesPerUserScreen from "./app/screen/ServiceFeeRevenuesPerUserScreen";
import AccountScreen from "./app/screen/AccountScreen";
import DeviceActivitiesScreen from "./app/screen/DeviceActivitiesScreen";
import AlertParkLevelScreen from "./app/screen/AlertParkLevelScreen";
import AlertDeviceStateScreen from "./app/screen/AlertDeviceStateScreen";
import IncidentWasteBinLevelScreen from "./app/screen/IncidentWasteBinLevelScreen";
import IncidentDeviceStateScreen from "./app/screen/IncidentDeviceStateScreen";
import DeviceParkScreen from "./app/screen/DeviceParkScreen";
import NotificationScreen from "./app/screen/NotificationScreen";
import SplashScreen from "./app/screen/SplashScreen";
import CheckInTicketScreen from "./app/screen/CheckInTicketScreen";
import CheckOutTicketScreen from "./app/screen/CheckOutTicketScreen";
import ParkLevelScreen from "./app/screen/ParkLevelScreen";
import ParkMapScreen from "./app/screen/ParkMapScreen";
import PricingScreen from "./app/screen/PricingScreen";
import ServiceScreen from "./app/screen/ServiceScreen";
import VehicleScreen from "./app/screen/VehicleScreen";
import CompanyScreen from "./app/screen/CompanyScreen";
import ParkActivitiesScreen from "./app/screen/ParkActivitiesScreen";
import HighestRevenueScreen from "./app/screen/HighestRevenueScreen";
import CompanyTicketScreen from "./app/screen/CompanyTicketScreen";
import RevenuesPerUserScreen from "./app/screen/RevenuesPerUserScreen";
import RentRevenuesPerParkScreen from "./app/screen/RentRevenuesPerParkScreen";
import RentRevenuesPerUserScreen from "./app/screen/RentRevenuesPerUserScreen";

const createBrowserHistory = require("history").createBrowserHistory;

function App() {
  return (
    <div className="App">
      <Router history={createBrowserHistory({ queryKey: false })}>
        <Switch>
          <Route exact path="/" component={SplashScreen} />
          <Route exact path="/login" component={LoginScreen} />
          <Route
            path="/dashboard"
            render={(props) => {
              const { url } = props.match;
              return (
                <HomeDashBoardScreen {...props}>
                  <Route
                    exact
                    path={`${url}/home/map_insights`}
                    component={ParkMapScreen}
                  />
                  <Route
                    exact
                    path={`${url}/home/summary_activities`}
                    component={ParkActivitiesScreen}
                  />
                  <Route
                    exact
                    path={`${url}/analytics/device_activities`}
                    component={DeviceActivitiesScreen}
                  />
                  <Route
                    exact
                    path={`${url}/analytics/revenues_trends`}
                    component={ParkLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/tickets/validated_tickets`}
                    component={CheckOutTicketScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts/park_levels`}
                    component={AlertParkLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts/park_levels/:bin_id`}
                    component={AlertParkLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/alerts/device_state`}
                    component={AlertDeviceStateScreen}
                  />
                  <Route
                    exact
                    path={`${url}/incidents/park_levels`}
                    component={IncidentWasteBinLevelScreen}
                  />
                  <Route
                    exact
                    path={`${url}/incidents/device_state`}
                    component={IncidentDeviceStateScreen}
                  />
                  <Route
                    exact
                    path={`${url}/tickets/issued_tickets`}
                    component={CheckInTicketScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/parks_&_devices`}
                    component={DeviceParkScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/accounts`}
                    component={AccountScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/notifications`}
                    component={NotificationScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/notifications/id`}
                    component={NotificationScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/finance/pricing`}
                    component={PricingScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/services`}
                    component={ServiceScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/vehicles`}
                    component={VehicleScreen}
                  />
                  <Route
                    exact
                    path={`/dashboard/companies`}
                    component={CompanyScreen}
                  />

                  <Route
                    exact
                    path="/dashboard/highest_revenues"
                    component={HighestRevenueScreen}
                  />

                  <Route
                    exact
                    path="/dashboard/company_ticket"
                    component={CompanyTicketScreen}
                  />

                  <Route
                    exact
                    path="/dashboard/revenues_park_per_user"
                    component={RevenuesPerUserScreen}
                  />

                  <Route
                    exact
                    path="/dashboard/servicefee_revenues_per_user"
                    component={ServiceFeeRevenuesPerUserScreen}
                  />

                  <Route
                    exact
                    path="/dashboard/servicefee_revenues_per_park"
                    component={ServiceFeeRevenuesPerParkScreen}
                  />

                  <Route
                    exact
                    path="/dashboard/rent_revenues_per_park"
                    component={RentRevenuesPerParkScreen}
                  />

                  <Route
                    exact
                    path="/dashboard/rent_revenues_per_user"
                    component={RentRevenuesPerUserScreen}
                  />
                </HomeDashBoardScreen>
              );
            }}
          />
          <Route
            exact
            path="/forgot_password"
            component={ForgotPasswordScreen}
          />
          <Route
            exact
            path="/reset_password/:username"
            component={ResetPasswordScreen}
          />
        </Switch>
      </Router>
      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
    </div>
  );
}

export default App;
